import React, { useEffect } from 'react';
import Header from './components/Header';
import Offer from './components/Offer/Offer';
import NavBoard from './components/design/NavBoard';
import Lenis from '@studio-freight/lenis'; // Импортируем Lenis
import WhatWeDo from './components/WhatWeDo/WhatWeDo';

function App() {

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.3,
      easing: (t) => 1 - Math.pow(1 - t, 3),

    smooth: true,
    direction: 'vertical',
    gestureDirection: 'vertical',
    scrollFromAnywhere: true,
    touchMultiplier: 2.0,
    wheelMultiplier: 1.0,

    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return () => {
      lenis.destroy();
    };
  }, []);

  return (
    <div className="App">
      <NavBoard />
      <Header />
      <Offer />
      <WhatWeDo />
    </div>
  );
}

export default App;
