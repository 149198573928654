import React, { useEffect, useRef } from "react";
import "./WhatWeDo.css";

const WhatWeDo = () => {
  const headingRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!headingRef.current) return;

      const rect = headingRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top <= windowHeight && rect.bottom >= 0) {
        const progress = Math.min(1, Math.max(0, 1 - rect.top / windowHeight));

        headingRef.current
          .querySelectorAll(".WhatWeDo__animation")
          .forEach((el) => {
            el.style.opacity = progress;
            el.style.transform = `translate3d(${10 - 10 * progress}%, ${
              15 - 15 * progress
            }%, 0) rotateX(${-45 + 45 * progress}deg)`;
          });

        headingRef.current
          .querySelectorAll(".WhatWeDo__animation-2")
          .forEach((el) => {
            el.style.opacity = progress;
            el.style.transform = `translate3d(${0 - 0 * progress}%, ${
              30 - 30 * progress
            }%, 0) rotateX(${-30 + 30 * progress}deg)`;
          });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section className="pt-[2.4rem] pb-4">
      <div className="relative">
        <div className="item__width absolute top-[-1rem] w-[2.2rem] h-[4.06rem] WhatWeDo__img"></div>
        <h2
          ref={headingRef}
          className="relative text-[#f8f6fd] WhatWeDo__perspective text-[1.84rem] leading-[0.85em] uppercase bold font-dela-gothic"
        >
          <span className="WhatWeDo__what WhatWeDo__animation block">Что</span>
          <span className="WhatWeDo__animation block">можем</span>
          <span className="WhatWeDo__animation block">
            мы
            <div className="WhatWeDo__animation-2 inline-block">
              сделать
              <br />
              для вас?
            </div>
          </span>
        </h2>
      </div>
      <div className="h-[1200px]"></div>
    </section>
  );
};

export default WhatWeDo;
