import React, { useEffect, useRef } from "react";
import Lenis from "@studio-freight/lenis";
import OfferVideo from "../../assets/7566042-hd_2048_1080_25fps.mp4";
import { motion } from "framer-motion";

const Offer = React.memo(() => {
  const videoRef = useRef(null);

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => 1 - Math.pow(1 - t, 3), // Настройка easing для плавного эффекта
      smooth: true,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    lenis.on("scroll", ({ scroll }) => {
      if (videoRef.current) {
        videoRef.current.style.transform = `translateY(${scroll * 0.3}px)`; // Скорость параллакса
      }
    });

    return () => {
      lenis.destroy();
    };
  }, []);

  return (
    <section className="w-full">
      <motion.h1
        className="relative z-50 text-center text-[2.80rem] font-monument uppercase text-white leading-0.82 mt-[0.263rem] mb-[-0.2rem]"
        initial={{ y: "0.83rem" }}
        animate={{ y: 0 }}
        transition={{
          y: { ease: [0.94, 0.02, 0.16, 0.99], duration: 1.1 },
        }}
      >
        sons
        <span className="text-[#C3FF40]">ai</span>
      </motion.h1>

      <motion.div
        className="
        relative
        z-20
        overflow-hidden
        h-[0.34rem]
        "
        initial={{ y: "0.41rem" }}
        animate={{ y: 0 }}
        transition={{
          y: {
            ease: [0.94, 0.02, 0.16, 0.99],
            duration: 1.1,
          },
        }}
      >
        <p
          className="
            absolute
            w-full
            left-0
            bottom-[-0.28rem]
            mt-[-.28rem]
            text-center
            text-[2.80rem]
            font-monument
            uppercase
            text-white
            leading-0.82
            "
          style={{
            background:
              "linear-gradient(90deg, #fff, #718053 42.71%, #141417 84.9%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          sonsai
        </p>
      </motion.div>

      <div
        className="
          relative
          z-10
          overflow-hidden
          h-[0.34rem]
          mt-[0.07em]
          transition-transform
        duration-[1100ms]
        "
      >
        <p
          className="
            absolute
            w-full
            left-0
            bottom-[-0.28rem]
            text-center
            text-[2.80rem]
            font-monument
            uppercase
            text-white
            leading-0.82
            "
          style={{
            background:
              "linear-gradient(90deg, #fff, #718053 42.71%, #141417 84.9%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          sonsai
        </p>
      </div>

      <div className="relative z-10 mt-[.36rem] overflow-hidden rounded-[.26rem]">
        <div className="mt-[-.1rem] pb-[.115rem] bg-[#151518] font-dela-gothic text-[.47rem] top-0 absolute z-20 uppercase text-[#C3FF40]">
          <div className="flex items-center flex-nowrap gap-[.11rem] overflow-hidden">
            <motion.span
              initial={{ y: "-100%" }}
              animate={{ y: 0 }}
              transition={{
                y: { ease: [0.1, -0.01, 0.34, 1], duration: 0.4, delay: 0.8 },
              }}
            >
              Создание
            </motion.span>
            <motion.span
              initial={{ y: "-100%" }}
              animate={{ y: 0 }}
              transition={{ ease: "easeOut", duration: 0.4, delay: 1 }}
            >
              сайтов
            </motion.span>
            <motion.span
              className="whitespace-nowrap"
              initial={{ y: "-100%" }}
              animate={{ y: 0 }}
              transition={{ ease: "easeOut", duration: 0.4, delay: 1.2 }}
            >
              под ключ
            </motion.span>
          </div>
          <svg
            className="block absolute z-1000 left-[0] top-full w-[.32rem] fill-[#151518]"
            viewBox="0 0 32 32"
            fill="none"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 32L0 0L32 0C14.3269 0 0 14.3269 0 32Z"
            ></path>
          </svg>

          <svg
            className="absolute block w-[.96rem] right-0 translate-x-[100%] top-[.1rem] fill-[#151518]"
            viewBox="0 0 96 72"
            fill="none"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M96 0H0V72H0.493286C9.30573 71.9312 17.7043 68.2304 23.7028 61.7643L71.5035 10.2377C77.5588 3.71038 86.0598 0.000976562 94.9633 0.000976562H96V0Z"
            ></path>
          </svg>
        </div>

        <video
          ref={videoRef}
          src={OfferVideo}
          className="w-full rounded-[.26rem] relative z-10 top-[-1rem] bg-black object-cover"
          autoPlay
          muted
          loop
        ></video>

        <div className="bg-[#151518] absolute  z-20  bottom-0 right-0 font-monument uppercase text-[#aaa] pr-[0.1rem] pt-[0.129rem] pl-[0.05rem]">
          <svg
            className="w-[.91rem] block absolute left-[1px] bottom-0 fill-[#151518] translate-x-[-100%]"
            viewBox="0 0 91 60"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.792969 60H90.9984V0.000976562H90.8508C82.8398 0.000976562 75.1202 3.00574 69.2173 8.42157L22.1764 51.5804C16.3349 56.9398 8.71439 59.9382 0.792969 60Z"
            ></path>
          </svg>
          <div className="w-[9.78rem] text-[0.157rem]">
            Lorem ipsum odor amet, consectetuer adipiscing elit. Quisque iaculis
            turpis metus nec tellus viverra enim habitasse. Blandit scelerisque
            maximus aliquet porta.
          </div>

          <svg
            className="block absolute bottom-[0.596rem] right-0 w-[.32rem] fill-[#151518]"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 32.001L32 32.001L32 0.000976562C32 17.6741 17.6731 32.001 0 32.001Z"
            ></path>
          </svg>
        </div>
      </div>
      <div className="absolute z-20 top-full left-[-1rem] bg-[#151518] rotate-[-1.9deg] shadow-custom overflow-hidden">
        <motion.div
          className="flex flex-nowrap text-[#f8f6fd] uppercase font-bold text-[.32rem] gap-[.24rem] whitespace-nowrap items-center py-[0.07rem]"
          animate={{ x: ["0%", "-50.4%"] }} // Проходим 100% для непрерывного эффекта
          transition={{ duration: 12, repeat: Infinity, ease: "linear" }}
        >
          {/* Дублируем содержимое для бесшовного эффекта */}
          {[...Array(2)].map((_, index) => (
            <React.Fragment key={index}>
              {/* Первый экземпляр текста */}
              <span className="text-[#f8f6fd] uppercase font-bold text-[.32rem]">
                бесплатный прототип
              </span>
              <svg
                className="h-[0.35rem]"
                width="16"
                height="43"
                viewBox="0 0 16 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8282 0.106387C13.9052 2.42778 13.9663 6.88287 14.0115 13.4717C13.9766 14.5134 13.9673 15.2768 13.9834 15.7618C13.4525 20.6701 13.1608 24.9462 13.1082 28.5899L3.4935 28.9089C3.19958 25.2766 2.62511 21.0293 1.7701 16.167L1.64227 13.882C1.25738 8.55315 1.00574 4.10438 0.887351 0.53568L13.8282 0.106387ZM14.7397 41.699L2.68238 42.099L2.37549 32.8481L14.4329 32.4481L14.7397 41.699Z"
                  fill="#C2FF3F"
                />
              </svg>
              {/* Второй экземпляр текста */}
              <span className="text-[#f8f6fd] uppercase font-bold text-[.32rem]">
                без предоплаты
              </span>
              <svg
                className="h-[0.35rem]"
                width="16"
                height="43"
                viewBox="0 0 16 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8282 0.106387C13.9052 2.42778 13.9663 6.88287 14.0115 13.4717C13.9766 14.5134 13.9673 15.2768 13.9834 15.7618C13.4525 20.6701 13.1608 24.9462 13.1082 28.5899L3.4935 28.9089C3.19958 25.2766 2.62511 21.0293 1.7701 16.167L1.64227 13.882C1.25738 8.55315 1.00574 4.10438 0.887351 0.53568L13.8282 0.106387ZM14.7397 41.699L2.68238 42.099L2.37549 32.8481L14.4329 32.4481L14.7397 41.699Z"
                  fill="#C2FF3F"
                />
              </svg>
              {/* Третий экземпляр текста */}
              <span className="text-[#f8f6fd] uppercase font-bold text-[.32rem]">
                скидка 10% на первый заказ
              </span>
              <svg
                className="h-[0.35rem]"
                width="16"
                height="43"
                viewBox="0 0 16 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8282 0.106387C13.9052 2.42778 13.9663 6.88287 14.0115 13.4717C13.9766 14.5134 13.9673 15.2768 13.9834 15.7618C13.4525 20.6701 13.1608 24.9462 13.1082 28.5899L3.4935 28.9089C3.19958 25.2766 2.62511 21.0293 1.7701 16.167L1.64227 13.882C1.25738 8.55315 1.00574 4.10438 0.887351 0.53568L13.8282 0.106387ZM14.7397 41.699L2.68238 42.099L2.37549 32.8481L14.4329 32.4481L14.7397 41.699Z"
                  fill="#C2FF3F"
                />
              </svg>
              {/* Четвертый экземпляр текста */}
              <span className="text-[#f8f6fd] uppercase font-bold text-[.32rem]">
                обслуживание
              </span>
              <svg
                className="h-[0.35rem]"
                width="16"
                height="43"
                viewBox="0 0 16 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8282 0.106387C13.9052 2.42778 13.9663 6.88287 14.0115 13.4717C13.9766 14.5134 13.9673 15.2768 13.9834 15.7618C13.4525 20.6701 13.1608 24.9462 13.1082 28.5899L3.4935 28.9089C3.19958 25.2766 2.62511 21.0293 1.7701 16.167L1.64227 13.882C1.25738 8.55315 1.00574 4.10438 0.887351 0.53568L13.8282 0.106387ZM14.7397 41.699L2.68238 42.099L2.37549 32.8481L14.4329 32.4481L14.7397 41.699Z"
                  fill="#C2FF3F"
                />
              </svg>
            </React.Fragment>
          ))}
        </motion.div>
      </div>
    </section>
  );
});

export default Offer;
