import React from 'react';
import MenuLogo from "../assets/logo.svg"; 
import Button from './design/Button';

const Header = () => {
  return (
    <header className="flex justify-between">
      <img src={MenuLogo} alt="logo" className=' h-full' />
      <Button text="связаться" />
    </header>
  );
};

export default Header;
