import React from "react";
import ArrowButton from "../../assets/arrow-button.svg";

const Button = ({text}) => {
  return (
    <button
      className="
    relative 
    z-[60]
    inline-block 
    cursor-pointer 
    transition-all 
    duration-[400ms] 
    ease-[cubic-bezier(0.65,0.05,0.36,1)] 
    text-[0.16rem] 
    leading-[1.2em] 
    uppercase 
    text-[#1c1b20] 
    bg-[#C3FF40] 
    py-[0.12rem] 
    pl-[0.24rem] 
    pr-[0.44rem] 
    text-center 
    rounded-[0.24rem] 
    border-0 
    font-dela-gothic
  "
      style={{
        transitionDuration: "0.4s",
        transitionTimingFunction: "cubic-bezier(0.65, 0.05, 0.36, 1)",
        transitionDelay: "0.2s",
      }}
    >
      <span
        className="
      inline-block 
      transition-transform 
      duration-[400ms] 
      ease-[cubic-bezier(0.65,0.05,0.36,1)] 
      transform
    "
      >
        {text}
      </span>
      <img
        src={ArrowButton}
        alt=""
        className="
      block 
      absolute 
      top-1/2 
      right-[0.16rem] 
      w-[0.2rem]
    "
        style={{
          transition: "transform .4s cubic-bezier(.65,.05,.36,1), opacity .4s cubic-bezier(.65,.05,.36,1)",
          transform: "translateY(-50%)",
        }}
      />
      <style jsx>{`
        button:hover span {
          transform: translateX(0.1rem); /* Сдвиг текста вправо при наведении */
        }
        button:hover img {
          transform: translateY(-50%) translateX(0.1rem) !important;
          opacity: 0; /* Исчезновение изображения при наведении на кнопку */
        }
      `}</style>
    </button>
  );
};

export default Button;
