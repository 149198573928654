import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./NavBoard.css";

const NavBoard = () => {
  const navItems = ["Услуги", "Портфолио", "Кабинет", "Контакты"];
  const navLinks = ["#services", "#portfolio", "/Lk", "#contacts"];
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY; // Получаем текущее положение скролла

      if (currentScrollY < lastScrollY && !isVisible) {
        // Скролл вверх
        setIsVisible(true);
      } else if (currentScrollY > lastScrollY && isVisible) {
        // Скролл вниз
        setIsVisible(false);
      }

      setLastScrollY(currentScrollY); // Обновляем последнее положение скролла
    };

    window.addEventListener("scroll", handleScroll); // Подписываемся на событие скролла

    return () => {
      window.removeEventListener("scroll", handleScroll); // Отписываемся от события при размонтировании
    };
  }, [lastScrollY, isVisible]); // Добавляем зависимости

  return (
    <div className="block z-[1000] fixed pointer-events-none top-0 left-0 w-full h-full py-[.16rem] px-[.36rem]">
      <motion.div
        className="absolute bottom-[0.2rem] w-min left-1/2 pointer-events-auto bg-[#C3FF40] rounded-[0.4rem]"
        initial={{ translateY: "150%", translateX: "-50%" }} // Начальное состояние (внизу)
        animate={{ translateY: isVisible ? "0%" : "150%" }} // Анимация при изменении состояния
        transition={{ duration: 0.6, ease: [0.65, 0.05, 0.36, 1] }} // Настройка перехода
      >
        <div
          className="
          navBoard__items
          flex
          justify-center
          items-center
          gap-[0.752rem]
          p-[0.226rem]
          mx-[0.015rem]
          bg-[#1C1B20]
          rounded-[0.4rem]
        "
        >
          {navItems.map((item, index) => (
            <a
              className="navBoard__item font-normal text-[.12rem] uppercase text-white font-Unbounded overflow-hidden relative "
              data-text={item}
              href={navLinks[index]}
              key={index}
            >
              <span>{item}</span>
            </a>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default NavBoard;
